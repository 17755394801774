:root {
  --transition: all 0.3s linear;
}
  
  /* section */
  .section {
    width: 90vw;
    margin: 5rem auto;
    max-width: var(--max-width);
  }
  /*
  =============== 
  Slider
  ===============
  */
  .title {
    text-align: center;
    margin-bottom: 2rem;
  }
  .title h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
  }
  .title span {
    font-size: 0.85em;
    color: var(--clr-primary-5);
    margin-right: 1rem;
    font-weight: 700;
  }
  .section-center {
    margin: 0 auto;
    margin-top: 4rem;
    width: 80vw;
    height: 500px;
    max-width: 800px;
    position: relative;
    overflow: hidden;
  }
  .person-img {
    margin-bottom: 1rem;
    width: 70%;
    height: 40px;
    justify-self: center;
    align-self: center;
    object-fit: contain;
  }
  article h4 {
    color: var(--clr-primary-5);
    margin-bottom: 0.25rem;
  }
  .text {
    max-width: 30em;
    margin: 0 auto;
    margin-top: 2rem;
    line-height: 2;
    color: var(--clr-grey-5);
  }
  .icon {
    font-size: 3rem;
    margin-top: 1rem;
    color: var(--clr-primary-5);
  }
  .prev,
  .next {
    position: absolute;
    top: 20px !important;
    transform: translateY(-50%);
    color: black;
    width: 1.25rem;
    height: 1.25rem;
    display: grid;
    place-items: center;
    border-color: transparent;
    font-size: 1rem;
    border-radius: 0;
    cursor: pointer;
    transition: var(--transition);
    background: transparent;
  }
  .prev:hover,
  .next:hover {
    background: transparent;
  }
  .prev {
    left: 0;
  }
  .next {
    right: 0;
  }
  @media (min-width: 800px) {
    .text {
      max-width: 45em;
    }
    .prev,
    .next {
      width: 2rem;
      height: 2rem;
      font-size: 1.5rem;
    }
  }
  article {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: var(--transition);
    display: flex;
    flex-direction: column;
  }
  article.activeSlide {
    opacity: 1;
    transform: translateX(0);
  }
  article.lastSlide {
    transform: translateX(-100%);
  }
  article.nextSlide {
    transform: translateX(100%);
  }
  