* {
  overflow: visible;
}

@font-face {
  font-family:"NeueHaasDisplayXXThin";
  src:url("fonts/NeueHaasDisplayXXThin.ttf") format("truetype");
  font-display:auto;
  font-style:normal;
  font-weight:100;
}

@font-face {
  font-family:"NeueHaasDisplayXThin";
  src:url("fonts/NeueHaasDisplayXThin.ttf") format("truetype");
  font-display:auto;
  font-style:normal;
  font-weight:100;
}

@font-face {
  font-family:"NeueHaasDisplayThin";
  src:url("fonts/NeueHaasDisplayThin.ttf") format("truetype");
  font-display:auto;
  font-style:normal;
  font-weight:100;
}

@font-face {
  font-family:"NeueHaasDisplayLight";
  src:url("fonts/NeueHaasDisplayLight.ttf") format("truetype");
  font-display:auto;
  font-style:normal;
  font-weight:100;
}

@font-face {
  font-family:"NeueHaasDisplayRoman";
  src:url("fonts/NeueHaasDisplayRoman.ttf") format("truetype");
  font-display:auto;
  font-style:normal;
  font-weight:100;
}

@font-face {
  font-family:"NeueHaasDisplayMedium";
  src:url("fonts/NeueHaasDisplayMedium.ttf") format("truetype");
  font-display:auto;
  font-style:normal;
  font-weight:100;
}

@font-face {
  font-family:"NeueHaasDisplayBold";
  src:url("fonts/NeueHaasDisplayBold.ttf") format("truetype");
  font-display:auto;
  font-style:normal;
  font-weight:100;
}

@font-face {
  font-family:"NeueHaasDisplayLightItalic";
  src:url("fonts/NeueHaasDisplayLightItalic.ttf") format("truetype");
  font-display:auto;
  font-style:normal;
  font-weight:100;
}

@font-face {
  font-family:"ABCArizonaPlusVariable";
  src:url("fonts/ABCArizonaPlusVariable-Trial.ttf") format("truetype");
}

@font-face {
  font-family: GTWalsheimPro-Bold;
  src: url("fonts/GTWalsheimPro-Bold.woff") format("woff"), url("fonts/GTWalsheimPro-Bold.ttf")  format("truetype");
}

@font-face {
  font-family: GTWalsheimPro-Regular;
  src: url("fonts/GTWalsheimPro-Regular.woff") format("woff"), url("fonts/GTWalsheimPro-Regular.ttf")  format("truetype");
}

@font-face {
  font-family: GTWalsheimPro-Medium;
  src: url("fonts/GTWalsheimPro-Medium.woff") format("woff"), url("fonts/GTWalsheimPro-Medium.ttf")  format("truetype");
}

@font-face {
  font-family: DMSans-Bold;
  src: url("fonts/DMSans-Bold.ttf") format("ttf"), url("fonts/DMSans-Bold.ttf")  format("truetype");
}

@font-face {
  font-family: Karla-Regular;
  src: url("fonts/Karla-Regular.ttf") format("ttf"), url("fonts/Karla-Regular.ttf")  format("truetype");
}

@font-face {
  font-family: Karla-Bold;
  src: url("fonts/Karla-Bold.ttf") format("ttf"), url("fonts/Karla-Bold.ttf")  format("truetype");
}

@font-face {
  font-family: Lora-Regular;
  src: url("fonts/Lora-Regular.ttf") format("ttf"), url("fonts/Lora-Regular.ttf")  format("truetype");
}

@font-face {
  font-family: Lora-Bold;
  src: url("fonts/Lora-Bold.ttf") format("ttf"), url("fonts/Lora-Bold.ttf")  format("truetype");
}

.b-canvas  {
  overflow: visible;
}

canvas  {
  overflow: visible;
}

#distortion-text-component  {
  overflow: visible;
}

p {
  margin: 0;
}

*::-webkit-scrollbar-track {
  border-radius: 3px;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: #efefef;
}
 
*::-webkit-scrollbar-thumb {
  background: #757575; 
  border-radius: 5px;  
}

*::-webkit-scrollbar-thumb:hover {
  background: #333333; 
  cursor: pointer;
}